/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function setAlert(alertModel) {
  return async (dispatch) => {
    dispatch({
      type: "ALERT_SET",
      payload: alertModel,
    });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function updateAlert(uuid = null, data) {
  return async (dispatch) => {
    dispatch({ type: "ALERT_UPDATE", payload: { alertUUID: uuid } });

    const url = "/api/alerts" + (uuid ? "/" + uuid : "") + ".json";

    const action = uuid ? axios.put : axios.post;

    return await action(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ALERT_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ALERT_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteAlert(uuid) {
  return async (dispatch) => {
    dispatch({ type: "ALERT_DELETE", payload: { alertUUID: uuid } });

    const url = "/api/alerts/" + uuid + ".json";

    return await axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ALERT_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ALERT_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchAlert(uuid) {
  return function (dispatch) {
    dispatch({ type: "ALERT_FETCH" });

    const url = "/api/alerts/" + uuid + ".json";

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthToken()
          ? AxiosConfig.getAuthConfig()
          : AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "ALERT_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error.message);
        if (
          error.message &&
          typeof error.message === "string" &&
          error.message.match(/Network Error/)
        ) {
          dispatch({
            type: "CORS_ERROR",
            payload: error,
          });
        } else {
          dispatch({
            type: "ALERT_FETCH_REJECTED",
            payload: error,
            unpublished:
              typeof error === "object" &&
              error.response &&
              error.response.status === 403,
          });
        }
      });
  };
}

/**
 *
 * @param alert
 * @returns {{template, available, name, alert_filters_uuids: (*|*[]), widget_settings, integration_uuid}}
 */
export const getAlertCurrentData = (alert) => {
  return {
    integration_uuid: alert.integration.uuid,
    available: alert.available,
    name: alert.name,
    template: alert.template,
    has_group_events_included: alert.has_group_events_included,
    widget_settings: { ...alert.widget_settings },
    filter_settings: { ...alert.filter_settings },
    show_private_events: alert.show_private_events ? 1 : 0,
    show_events_with_images: alert.show_events_with_images ? 1 : 0,
  };
};

export const fetchItems = (
  uuid,
  limit = null,
  page = null,
  filters = [],
  preservePreviousItems = false
) => {
  return async (dispatch) => {
    dispatch({ type: "ALERT_ITEMS_FETCH" });

    const postData = {
      alert_uuid: uuid,
    };

    if (filters && filters.length) {
      postData["alert_filters"] = filters;
    }

    const queryParams = AxiosConfig.objectToURLQuery({
      page,
      limit,
      uuid,
    });

    const url = "/api/alerts/items.json?" + queryParams;

    return await axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        postData,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: `ALERT_ITEMS_FETCH_FULFILLED`,
          payload: response.data,
          preservePreviousItems,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `ALERT_ITEMS_FETCH_REJECTED`,
          payload: error,
          preservePreviousItems,
        });
      });
  };
};

/**
 *
 * @param uuid
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchItem = (alertUUID, itemUUID) => {
  return async (dispatch) => {
    dispatch({ type: "ALERT_ITEM_FETCH" });

    const url = `/api/alerts/items/${alertUUID}.json?item_uuid=${itemUUID}`;

    return await axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: `ALERT_ITEM_FETCH_FULFILLED`,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `ALERT_ITEM_FETCH_REJECTED`,
          payload: error,
        });
      });
  };
};

export const hideAlert = () => {
  return async (dispatch) => {
    dispatch({ type: "ALERT_HIDE" });
  };
};

export const showAlert = () => {
  return async (dispatch) => {
    dispatch({ type: "ALERT_SHOW" });
  };
};
