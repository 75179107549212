/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as alertActions from "../../actions/alert";

export function useAlertStore() {
  const dispatch = useDispatch();
  const _alert = useSelector((store) => store.alert);
  const _alertEvents = useSelector((store) => store.alertEvents);

  const fetchAlert = useCallback(
    async (uuid) => await dispatch(alertActions.fetchAlert(uuid)),
    [dispatch]
  );

  const fetchItems = useCallback(
    async (uuid, limit, page, filters, preservePreviousItems) =>
      await dispatch(
        alertActions.fetchItems(
          uuid,
          limit,
          page,
          filters,
          preservePreviousItems
        )
      ),
    [dispatch]
  );

  const fetchItem = useCallback(
    async (uuid, itemUUID) =>
      await dispatch(alertActions.fetchItem(uuid, itemUUID)),
    [dispatch]
  );

  const deleteAlert = useCallback(
    async (uuid) => await dispatch(alertActions.deleteAlert(uuid)),
    [dispatch]
  );

  const updateAlert = useCallback(
    async (uuid, data) => await dispatch(alertActions.updateAlert(uuid, data)),
    [dispatch]
  );

  const setAlert = useCallback(
    async (model) => await dispatch(alertActions.setAlert(model)),
    [dispatch]
  );

  const showAlert = useCallback(
    async () => await dispatch(alertActions.showAlert()),
    [dispatch]
  );

  const hideAlert = useCallback(
    async () => await dispatch(alertActions.hideAlert()),
    [dispatch]
  );

  return {
    alert: _alert,
    alertEvents: _alertEvents,
    deleteAlert,
    updateAlert,
    fetchAlert,
    setAlert,
    fetchItems,
    fetchItem,
    showAlert,
    hideAlert,
  };
}
