/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/03/2023.
 */
import React from "react";

const initialState = {
  lastClosed: null,
  lastEventSlug: null,

  recentLoadedEventSlug: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "ALERT_HIDE":
      return {
        ...state,
        lastClosed: new Date(),
        lastEventSlug: state.recentLoadedEventSlug,
      };

    case "ALERT_SHOW":
      return {
        ...state,
      };

    case "ALERT_EVENTS_FETCH_FULFILLED":
      return {
        ...state,
        recentLoadedEventSlug:
          action.payload.events && action.payload.events.length
            ? action.payload.events[0].slug
            : null,
      };
  }

  return state;
}
