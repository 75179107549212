/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/02/2023.
 */
import React from "react";

const initialState = {
  model: null,

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  show: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "ALERT_SET":
      if (!action.payload) {
        return { ...initialState };
      }

      return {
        ...initialState,
        model: action.payload,
      };

    case "ALERT_FETCH_FULFILLED":
      try {
        return {
          ...state,
          fetch: false,
          fetchSuccess: true,
          model: {
            ...state.model,
            ...action.payload,
          },
        };
      } catch (e) {
        console.log("Alert Fetch Fulfilled, an exception occurred: ", e);
      }

    case "ALERT_HIDE":
      return {
        ...state,
        show: false,
      };

    case "ALERT_SHOW":
      return {
        ...state,
        show: true,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model ? { ...state.model } : null,
      };
  }

  return state;
}
