/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */
import { applyMiddleware, compose, createStore } from "redux";

import EventsMiddleware from "../../middleware/events";

import logger from "redux-logger";
import thunk from "redux-thunk";

import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "./reducers";
import AlertsBuilderMiddleware from "../../middleware/AlertsBuilderMiddleware";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import IntegrationFiltersMiddleware from "../../middleware/integrationFilters";

export const history = createBrowserHistory();

const middlewareComponents = [
  thunk,
  routerMiddleware(history),
  EventsMiddleware,
  AlertsBuilderMiddleware,
  IntegrationFiltersMiddleware,
];

const persistConfig = {
  key: "dc-alert",
  storage,
  whitelist: ["alertEmbedded"],
  // transforms: [filtersReducerTransform]
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewareComponents.push(logger);
}
export const middleware = applyMiddleware(...middlewareComponents);

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);
export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(middleware)
  );

  return { store };
}
