import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import main from "./main";
import alert from "./alert";
import alertEvents from "./alertEvents";
import alertEmbedded from "./alertEmbedded";

const createRootReducer = (history) =>
  combineReducers({
    main,
    router: connectRouter(history),
    alert,
    alertEvents,
    alertEmbedded,
  });

export default createRootReducer;
