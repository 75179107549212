/**
 *
 * @param year The full year designation is required for cross-century date accuracy. If year is between 0 and 99 is used, then year is assumed to be 1900 + year.
 * @param monthIndex The month as a number between 0 and 11 (January to December).
 * @param date The date as a number between 1 and 31.
 * @param hours Must be supplied if minutes is supplied. A number from 0 to 23 (midnight to 11pm) that specifies the hour.
 * @param minutes Must be supplied if seconds is supplied. A number from 0 to 59 that specifies the minutes.
 * @param seconds Must be supplied if milliseconds is supplied. A number from 0 to 59 that specifies the seconds.
 * @param ms A number from 0 to 999 that specifies the milliseconds.
 * @returns {Date}
 * @constructor
 */

function Time(year, monthIndex, hoursate, hours, minutes, seconds, ms) {
  var x;
  switch (arguments.length) {
    case 0:
      x = new Date();
      break;
    case 1:
      x = new Date(year);
      break;
    case 2:
      x = new Date(year, monthIndex);
      break;
    case 3:
      x = new Date(year, monthIndex, hoursate);
      break;
    case 4:
      x = new Date(year, monthIndex, hoursate, hours);
      break;
    case 5:
      x = new Date(year, monthIndex, hoursate, hours, minutes);
      break;
    case 6:
      x = new Date(year, monthIndex, hoursate, hours, minutes, seconds);
      break;
    default:
      x = new Date(year, monthIndex, hoursate, hours, minutes, seconds, ms);
  }
  x.__proto__ = Time.prototype;

  return x;
}

Time.prototype.__proto__ = Date.prototype;

Time.Unit = {
  SECOND: "second",
  MINUTE: "minute",
  HOUR: "hour",
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
};

/**
 * Check if date is after `this` date
 * @param date
 * @returns {boolean}
 */
Time.prototype.isAfter = function (date) {
  return this > date;
};

/**
 * Check if date is before `this` date
 * @param date
 * @returns {boolean}
 */
Time.prototype.isBefore = function (date) {
  return this < date;
};

/**
 * Check if date is same as `this` date (default granularity is day).
 * @param date
 * @param granularity = 'day', 'month', 'year'
 * @returns {boolean}
 */
Time.prototype.isSame = function (date, granularity = Time.Unit.DAY) {
  switch (granularity) {
    case Time.Unit.DAY:
      return this.toDateString() === date.toDateString();
    case Time.Unit.MONTH:
      return (
        this.getFullYear() === date.getFullYear() &&
        this.getMonth() === date.getMonth()
      );
    case Time.Unit.YEAR:
      return this.getFullYear() === date.getFullYear();
    default:
      return this.toDateString() === date.toDateString();
  }
};

/**
 * Add amount of units to `this` date
 * @param amount
 * @param unit = 'second', 'minute', 'hour', 'day', 'month', 'year'
 * @returns {Time}
 */
Time.prototype.add = function (amount, unit) {
  switch (unit) {
    case Time.Unit.SECOND:
      return new Time(this.setSeconds(this.getSeconds() + amount));
    case Time.Unit.MINUTE:
      return new Time(this.setMinutes(this.getMinutes() + amount));
    case Time.Unit.HOUR:
      return new Time(this.setHours(this.getHours() + amount));
    case Time.Unit.DAY:
      return new Time(this.setDate(this.getDate() + amount));
    case Time.Unit.MONTH:
      return new Time(this.setMonth(this.getMonth() + amount));
    case Time.Unit.YEAR:
      return new Time(this.setFullYear(this.getFullYear() + amount));
    default:
      return new Time(this.setDate(this.getDate() + amount));
  }
};

export default Time;
