/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as eventsActions from "../../actions/alertEvents";

export function useAlertEventsStore() {
  const dispatch = useDispatch();
  const _events = useSelector((store) => store.alertEvents);

  const fetchAlertEvents = useCallback(
    async (
      calendarUUID,
      from,
      to,
      limit,
      page,
      filters,
      preservePreviousEvents = false
    ) =>
      await dispatch(
        eventsActions.fetchAlertEvents(
          calendarUUID,
          from,
          to,
          limit,
          page,
          filters,
          preservePreviousEvents
        )
      ),
    [dispatch]
  );

  const resetEventsCollection = useCallback(
    () => dispatch(eventsActions.resetEventsCollection()),
    [dispatch]
  );

  return {
    events: _events,
    fetchAlertEvents,
    resetEventsCollection,
  };
}
