/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

const initialState = {
  collection: [],
  locations: [],

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  page: 1,
  totalRows: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "ALERT_EVENTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "ALERT_EVENTS_FETCH_FULFILLED":
      const events = action.preservePreviousEvents
        ? state.collection.concat(action.payload.events)
        : action.payload.events;

      const now = Number.parseInt(new Date().getTime() / 1000);
      return {
        ...state,
        collection: events.filter((event) => event.end_time > now),
        locations: action.payload.locations,
        page: action.payload.pagination ? action.payload.pagination.page : 0,
        totalRows: action.payload.pagination
          ? action.payload.pagination.count
          : events.length,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "ALERT_EVENTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
        collection: action.preservePreviousEvents ? state.collection : [],
      };

    case "ALERT_EVENTS_COLLECTION_RESET":
      return {
        ...initialState,
      };
  }

  return state;
}
