/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2023
 */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

const calculateTimeLeft = (date) => {
  const now = new Date();
  const timeLeft = date - now;
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  if (timeLeft < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  return { days, hours, minutes, seconds };
};

const Countdown = ({ eventStartTime }) => {
  const date = useMemo(() => new Date(eventStartTime * 1000), [eventStartTime]);

  const [countdown, setCountdown] = useState(calculateTimeLeft(date));

  useEffect(() => {
    // set timeout every second that will decrease time left
    const timer = setTimeout(() => {
      setCountdown(calculateTimeLeft(date));
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown]);

  return (
    <div>
      <table className={"dc--banner-container_countdown"}>
        <thead className={"dc--banner-container_countdown_thead"}>
          <tr>
            <th align={"center"} width={"25%"}>
              {countdown.days}
            </th>
            <th align={"center"} width={"25%"}>
              {countdown.hours}
            </th>
            <th align={"center"} width={"25%"}>
              {countdown.minutes}
            </th>
            <th align={"center"} width={"25%"}>
              {countdown.seconds}
            </th>
          </tr>
        </thead>
        <tbody className={"dc--banner-container_countdown_tbody"}>
          <tr>
            <td align={"center"}>Days</td>
            <td align={"center"}>Hrs</td>
            <td align={"center"}>Min</td>
            <td align={"center"}>Sec</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Countdown.defaultProps = {};

Countdown.propTypes = {};

export default Countdown;
