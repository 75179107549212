import React, { createContext } from "react";
import { IT } from "../../../consts";
import CCBStrategy from "./../../calendar/strategies/CCBStrategy";
import PCOStrategy from "./../../calendar/strategies/PCOStrategy";
import GoogleStrategy from "./../../calendar/strategies/GoogleStrategy";
import BreezeStrategy from "./../../calendar/strategies/BreezeStrategy";

const IntegrationContext = React.createContext("");

const StragiesMappping = {
  [IT.ccb]: CCBStrategy,
  [IT.pco]: PCOStrategy,
  [IT.google]: GoogleStrategy,
  [IT.breeze]: BreezeStrategy,
};
/**
 *
 * @param integrationName
 * @returns {CalendarIntegrationStrategy}
 */
export const getStrategy = (integrationType, widget) => {
  if (!StragiesMappping[integrationType]) {
    return null;
  }
  return new StragiesMappping[integrationType](widget);
};

export default IntegrationContext;
