/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/03/2023
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const Cta = (props) => {
  const widgetSettings = useContext(WidgetSettingsContext);

  const cta = useMemo(() => {
    const useDefaultLabel = widgetSettings["dcaCTAActionDefaultLabel"];
    const dcaCTAAction = widgetSettings["dcaCTAAction"];

    let cta = props.ctas[dcaCTAAction];

    if (!cta) {
      return null;
    }

    const label = !useDefaultLabel
      ? widgetSettings["dcaCTAActionLabel"]
      : cta.label;
    const url = cta.url;

    return { label, url };
  }, [widgetSettings, props.ctas]);

  if (!cta || !cta.url) {
    return null;
  }

  const target =
    widgetSettings["dcaLinkTargetNewTab"] === 1 ? cta.url : "_self";

  return (
    <div
      className={classNames(
        "dce--bar_cta-container dce--bar_cta-container_button",
        {
          "dce--bar_cta-container_button_flash-effect": props.initialized,
        }
      )}
    >
      <a href={cta.url} className={"dce--bar_cta"} target={target}>
        {cta.label}
      </a>
    </div>
  );
};

Cta.defaultProps = {};

Cta.propTypes = {
  initialized: PropTypes.bool.isRequired,
  ctas: PropTypes.shape({
    linkFirst: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    linkLast: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

export default Cta;
