/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/04/2019.
 */
import { PLANS } from "../../../consts";
import Time from "../../../helpers/time";

/**
 *
 * @param organization
 * @returns {null|subscription|{uuid}|boolean}
 */
export const hasSubscription = (organization) => {
  if (
    !organization.subscription ||
    (Array.isArray(organization.subscription) &&
      organization.subscription.length === 0)
  ) {
    return false;
  }

  if (new Time().isAfter(new Date(organization.subscription.expiry * 1000))) {
    if (
      organization.subscription.available /* &&
      organization.subscription.code !== PLANS.free*/
    ) {
      return organization.subscription;
    }

    return false;
  }

  return organization.subscription;
};

/**
 * Tells whether the trial has finished.
 * @param organization
 * @returns {boolean} false if not on trial or on trial but trial is not finished, true otherwise.
 */
export const hasTrialFinished = (organization) => {
  const subscription = hasSubscription(organization);
  const isTrial = subscription && subscription.is_trial * 1 ? true : false;

  return (
    !isTrial ||
    (isTrial &&
      new Time().isAfter(new Date(organization.subscription.expiry * 1000)))
  );
};

export const getOrganizationSubscriptionPlan = (organization) => {
  const hasPlan = hasSubscription(organization);
  if (!hasPlan) {
    return null;
  }

  return organization.model.organizations_plans.find((i) => i.available);
};
