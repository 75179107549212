/**
 * Sleep function. Returns a promise that resolves after ms milliseconds.
 * @param ms
 * @returns {Promise<unknown>}
 */
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default sleep;
