/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

const initialState = {
  pathname: '/',
  previousPathname: null
};

export default function reducer(
  state = {
    ...initialState
  },
  action) {


  switch (action.type) {

    case "@@router/LOCATION_CHANGE":


      return {
        ...state,

      };
  }

  return state;
}