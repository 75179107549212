/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import AxiosConfig from "./AxiosConfig";
//console.log('Hello Jason!');

import React from "react";

import configureStore, { history } from "./apps/alerts/store";

import RouterClass from "./apps/alerts/router";
import { dceDebug } from "./helpers/debug";
import { onDocumentLoad, onLocationChange } from "./helpers/launcher";
import { WT } from "./apps/admin/consts/widget";
import ReactDOM from "react-dom";
import PreLoader from "./apps/alerts/PreLoader";
require("./helpers/date");
require("./sass/alerts.scss");

/**
 * Not initialized widget will contain class only without ID. The ID will be set after initialization.
 * The widget does not require any dom element to be placed in the page. It will create its own div.
 * @param kind
 * @param backendAddress
 * @param reactConfig
 * @returns {boolean}
 */
const runWidget = (kind, backendAddress, reactConfig) => {
  /*if (window.__DCE[kind].initializedWidgets.indexOf(uuid) >= 0) {
    dceDebug("Widget already initialized");
    window.__DCE[kind].init = false;
    window.__DCE[kind].initializedWidgets.push(uuid);
    return false;
  }


  window.__DCE[kind].initializedWidgets.push(uuid);

  const { store } = reactConfig.configureStore();
  //console.log(store);
  dceDebug(store);
*/
  const root = document.createElement("div");
  root.setAttribute("class", "dce-widget");
  document.body.appendChild(root);

  ReactDOM.render(<PreLoader />, root);
};

const runWidgetInitialization = (kind, widgetSelector, reactConfig) => {
  onDocumentLoad(kind, () => {
    runWidget(kind, widgetSelector, reactConfig);
  });

  onLocationChange(() => {
    dceDebug("onLocationChange");
    // TODO ensure this is needed
    runWidget(kind, widgetSelector, reactConfig);
  });
};

(() => {
  const ID = new Date() * 1;

  dceDebug("script ID", ID, window.DCESCRIPTID);

  if (window.__DCE && window.__DCE[WT.alerts]) {
    return;
  } else {
    window.DCESCRIPTID = ID;
    window.__DCE = window.__DCE || {};
    window.__DCE[WT.alerts] = {
      isWidgetLoaded: false,
      initializedWidgets: [],
    };
  }

  // get parameters
  AxiosConfig.setCalendarAddress(process.env.API_ADDRESS);

  runWidgetInitialization(WT.alerts, ".dce-alerts", {
    RouterClass,
    configureStore,
    history,
  });
})();
