/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/03/2023
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const useOnScrollPage = ({ callback, initialized, when, deps }) => {
  const callbackTriggered = React.useRef(false);

  const checkScroll = useCallback(() => {
    if (callbackTriggered.current) {
      return;
    }

    let trigger = false;
    const maxWindowScroll = document.body.scrollHeight - window.innerHeight;
    if (when === "little" && window.scrollY > 100) {
      trigger = true;
    } else if (when === "middle" && window.scrollY > maxWindowScroll / 2) {
      trigger = true;
    } else if (when === "end" && window.scrollY >= maxWindowScroll) {
      trigger = true;
    }

    if (!trigger) {
      return;
    }

    callbackTriggered.current = true;
    callback();
  }, [callbackTriggered.current, when, callback]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (callbackTriggered.current) {
      window.removeEventListener("scroll", checkScroll);
      return;
    }
    window.addEventListener("scroll", checkScroll);

    checkScroll();
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [...deps, callbackTriggered.current, when, initialized, callback]);

  //return isScrolling;
};

export default useOnScrollPage;
