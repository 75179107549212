/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/02/2023
 */

import React, { useCallback, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useAlertEventsStore } from "../../../../hooks/redux/alertEvents";
import useWindowSize from "../../../../hooks/useWindowSize";
import Cta from "./Cta";
import CloseIcon from "./CloseIcon";
import classNames from "classnames";
import { useAlertStore } from "../../../../hooks/redux/alert";
import sleep from "../../../../helpers/sleep";
import classnames from "classnames";
import { WT } from "../../../admin/consts/widget";
import { PT } from "../../../../consts";
import Countdown from "./Countdown";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const monthWeekdayFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  month: "long",
  day: "numeric",
});

const dayFormatter = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
});

const timeFormatter = new Intl.DateTimeFormat("en-US", {
  hour12: true,
  hour: "numeric",
  minute: "2-digit",
});

const useSetHtmlPadding = ({ embedded, uuid, widgetDOMId, show, position }) => {
  const windowSize = useWindowSize();
  const initialMarginOffset = 0;

  useEffect(() => {
    const alert = document.getElementById(uuid);
    // const alert = document.querySelector(`.${widgetDOMId}`);
    const html = document.querySelector("html");

    if (embedded) {
      if (show) {
        if (position === "dc-banner_top") {
          // obtain margin top from the html element
          // and apply it to the alert
          const marginTop = html.offsetTop;
          alert.style.marginTop = marginTop + "px";
          //setInitialMarginOffset(marginTop);
          html.style.setProperty(
            "padding-top",
            alert.clientHeight + "px",
            "important"
          );
        } else {
          html.style.setProperty(
            "padding-bottom",
            alert.clientHeight + "px",
            "important"
          );
        }
      } else {
        alert.style.marginTop = 0 + "px";

        html.style.setProperty(
          "padding-top",
          initialMarginOffset + "px",
          "important"
        );
      }
    }
  }, [windowSize, show]);
};

const Banner = (props) => {
  const { events } = useAlertEventsStore();
  const { alert } = useAlertStore();
  const [initialMarginOffset, setInitialMarginOffset] = React.useState(0);

  const [initialized, setInitialized] = React.useState(false);

  const widgetSettings = useContext(WidgetSettingsContext);

  useSetHtmlPadding({
    embedded: props.embedded,
    widgetDOMId: props.widgetDOMId,
    show: initialized,
    position: widgetSettings["dcaPosition"],
    uuid: alert.model.uuid,
  });

  // sets html transition on mount
  useEffect(() => {
    const html = document.querySelector("html");
    html.style.setProperty(
      "transition",
      "padding-top 0.6s ease-in",
      "important"
    );
  }, []);

  useEffect(() => {
    // modify embedding node with class names accordingly.
    const embeddingNode = document.getElementById(alert.model.uuid);

    if (embeddingNode) {
      embeddingNode.className = classnames(
        `a${alert.model.uuid}`,
        props.widgetDOMId,
        WT.alerts,
        `dc-${WT.alerts}-${alert.model.template}`,
        widgetSettings["dcaPosition"],
        widgetSettings["dcaPlacement"],
        {
          "mobile-preview": props.previewType === PT.mobile,
          bounce: initialized,
          "bounce-off": !initialized,
        }
      );
    }
  }, [alert.model.widget_settings, initialized]);

  useEffect(() => {
    if (alert.show) {
      setInitialized(true);
    } else {
      if (props.embedded) {
      }
    }
  }, [alert.show]);

  const event = events.collection[0];

  const onCloseClick = useCallback(
    (e) => {
      e.preventDefault();
      setInitialized(false);
      sleep(500).then(() => {
        // to ensure the transition is done
        // setInitialized(false);
        props.onClose();
      });
    },
    [initialized]
  );

  const formattedDate = useMemo(() => {
    return monthWeekdayFormatter.format(new Date(event.start_time * 1000));
  }, []);

  const formattedTime = useMemo(() => {
    /*if (event.allDay) {
      return <span>All Day</span>;
    }
*/
    return timeFormatter.format(new Date(event.start_time * 1000));
  }, []);

  const prefix =
    widgetSettings["dcaOpeningText"] &&
    widgetSettings["dcaOpeningText"].length ? (
      <div>{widgetSettings["dcaOpeningText"]}</div>
    ) : null;

  const postfix =
    widgetSettings["dcaClosingText"] &&
    widgetSettings["dcaClosingText"].length ? (
      <div>{widgetSettings["dcaClosingText"]}</div>
    ) : null;

  const closeButton = widgetSettings["dcaCanHide"] ? (
    <div
      className={classNames("dc--banner-close_container", {
        "dc--banner-close_container-show": initialized,
      })}
      onClick={onCloseClick}
    >
      <CloseIcon />
    </div>
  ) : null;

  if (!alert.show && !initialized) {
    return null;
  }

  const showCountDownTimer = widgetSettings["dcaShowCountdown"];
  const showCountDownTimerForAllDay =
    widgetSettings["dcaShowCountdownForAllDay"];

  const countdownTimer =
    showCountDownTimer && (showCountDownTimerForAllDay || !event.allDay) ? (
      <Countdown eventStartTime={event.start_time} />
    ) : null;

  const cta = widgetSettings["dcaShowCTA"] ? (
    <Cta ctas={event.ctas} initialized={initialized} />
  ) : null;

  const time = !event.allDay ? (
    <span>
      <span>at</span>&nbsp;
      <strong>{formattedTime}</strong>.
    </span>
  ) : null;

  return (
    <div
      className={classNames(`dc--banner`, { "bg-flash-effect": initialized })}
    >
      <div
        className={classNames("dc--banner-container", {
          "color-flash-effect": initialized,
        })}
      >
        <div className={"dc--banner-container_description"}>
          {prefix}
          <strong>{event.name}</strong>
          <div className={"dc--banner-container_date"}>
            <span>on&nbsp;</span>

            <strong>{formattedDate}</strong>
          </div>

          {time}
          {postfix}
        </div>
        {cta}
        {countdownTimer}
      </div>
      {closeButton}
    </div>
  );
};

Banner.defaultProps = {};

Banner.propTypes = {
  widgetUUID: PropTypes.string.isRequired,
  widgetDOMId: PropTypes.string.isRequired,
  embedded: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Banner;
