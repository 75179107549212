/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/02/2023
 */

import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import { Provider } from "react-redux";
import { getEmbeddableSource } from "../../helpers/launcher";
import configureStore from "./store";
import axios from "axios";
import AxiosConfig from "../../AxiosConfig";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const PreLoader = (props) => {
  //const { alerts, fetchAlerts } = useAlertsStore();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    axios.get(AxiosConfig.getEndpointAddress() + "/api/alerts.json").then(
      (response) => {
        setAlerts(response.data);
      },
      (error) => {
        // TODO
      }
    );
    return () => {};
  }, []);

  const alertsLoading = !alerts.length;

  const alertsPortals = useMemo(() => {
    return alerts.map((alert) => {
      const node = document.createElement("div");
      node.setAttribute("class", "dce-alerts-preloader");
      document.body.appendChild(node);
      const { store } = configureStore();
      const persistor = persistStore(store);
      return ReactDOM.createPortal(
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router alertData={alert} node={node} />
          </PersistGate>
        </Provider>,
        node
      );
    });
  }, [alerts]);

  if (alertsLoading) {
    return null;
  }

  return alertsPortals;
};

PreLoader.defaultProps = {};

PreLoader.propTypes = {};

export default PreLoader;
