/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/03/2023
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

export function useAlertEmbeddedStore() {
  const dispatch = useDispatch();
  const _alertEmbedded = useSelector((store) => store.alertEmbedded);

  return {
    alertEmbedded: _alertEmbedded,
  };
}
